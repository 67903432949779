import { ref } from "vue";
import { AdminServices } from "..";
import { ActionPropsUser, Action } from "../../models/action";
import { AdProps, Ad } from "../../models/ad";
import { OpenAIAssistant } from "../../models/openAIAssistant";
import { Profile, ProfileUserProps, ProfileSystemProps } from "../../models/profile";
import { Roadtrip } from "../../models/roadtrip";
import { ExtractOperationStartResult } from "../adminOperationsService";
import { User } from "../../models/user";
import { Campground, CampgroundProps } from "../../models/campground";
import { OpenAIThread } from "../../models/openAIThread";
import { OpenAIMessage } from "../../models/openAIMessage";

export function createPrerenderAdminServices(): AdminServices {
  return prerenderAdminServiceStubs;
}

export const prerenderAdminServiceStubs: AdminServices = {
  adminActionsService: {
    createAction: function (actionParams: ActionPropsUser): Promise<Action> {
      throw new Error("Function not implemented.");
    },
    updateAction: function (actionId: string, action: ActionPropsUser): Promise<Action> {
      throw new Error("Function not implemented.");
    },
    getActions: function (): Promise<Action[]> {
      throw new Error("Function not implemented.");
    },
    deleteAction: function (actionId: string): Promise<void> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminAdsService: {
    createAd: function (adParams: AdProps): Promise<Ad> {
      throw new Error("Function not implemented.");
    },
    getAd: function (adId: string): Promise<Ad> {
      throw new Error("Function not implemented.");
    },
    getAds: function (limit?: number | undefined, offset?: number | undefined): Promise<Ad[]> {
      throw new Error("Function not implemented.");
    },
    updateAd: function (adId: string, ad: AdProps): Promise<Ad> {
      throw new Error("Function not implemented.");
    },
    deleteAd: function (adId: string): Promise<void> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminProfilesService: {
    getProfileForUser: function (userId: string): Promise<Profile> {
      throw new Error("Function not implemented.");
    },
    getProfiles: function (limit?: number | undefined, offset?: number | undefined): Promise<Profile[]> {
      throw new Error("Function not implemented.");
    },
    updateProfile: function (profileId: string, profile: ProfileUserProps & ProfileSystemProps): Promise<Profile> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminUsersService: {
    getUserByEmail: function (email: string): Promise<User | undefined> {
      throw new Error("Function not implemented.");
    },
    getUserByUserId: function (userId: string): Promise<User | undefined> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminOperationsService: {
    startUsersExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startRoadtripsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startStopsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startInterestsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startUserInterestsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startRoadtripsSharedWithExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startReferralsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startAdsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startActionsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startAlertsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startCampgroundsExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startStopTemplatesExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    startRoadtripTemplatesExtractOperation: function (): Promise<ExtractOperationStartResult> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminRoadtripsService: {
    getRoadtrip: function (roadtripId: string): Promise<Roadtrip> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminCampgroundsService: {
    getCampground(campgroundId: string): Promise<{ campground: Campground; campgroundOverrides: Campground; }> {
      throw new Error("Function not implemented.");
    },
    updateCampground: function (campgroundId: string, campground: CampgroundProps): Promise<Campground> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref(),
  },
  adminOpenAIAssistantsService: {
    getOpenAIAssistant: function (openAIAssistantId: string): Promise<OpenAIAssistant> {
      throw new Error("Function not implemented.");
    },
    getOpenAIAssistants: function (): Promise<OpenAIAssistant[]> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminOpenAIThreadsService: {
    getOpenAIThread: function (openAIThreadId: string): Promise<OpenAIThread> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
  },
  adminOpenAIMessagesService: {
    getOpenAIMessages: function (openAIThreadId: string): Promise<Array<OpenAIMessage>> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
    lastErrorMessage: ref()
 }
};
